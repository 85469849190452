* {
    min-width: 0;
}

html, body {
    font-family: Roboto, Arial, sans-serif;
    scroll-padding-top: 80px;
    @media screen and (max-width: 768px) {
        scroll-padding-top: 150px;
    }
}

input[type=color]:focus, input[type=date]:focus, input[type=datetime]:focus, input[type=datetime-local]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, input[type=password]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=url]:focus, input[type=week]:focus, input:not([type]):focus, textarea:focus, select:focus {
    border-color: $emerald;
}

h1, h2, h3, h4, h5, h6 {
    letter-spacing: normal;
}

h1 {
    background: linear-gradient(90deg, #F7FFB0 0%, #CADB2A 85.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Roboto;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 7.5px;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 1.8px;
    }
    small {
        font-size: 32px;
        font-weight: 400;
        @media screen and (max-width: 768px) {
            font-size: 18px;
        }
    }
}

h2 {
    color: $white;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 35px; /* 97.222% */
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 19px;
    }
}

h3 {
    color: $emerald;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    display: inline-block;
    &.underline {
        padding-top: 20px;
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            background: linear-gradient(90deg, #A71865 0%, #CEDB46 99.45%);
            border-radius: 30px;
            margin-top: 7px;
        }
    }
    &.center {
        color: $lightemerald;
        font-weight: 700;
    }
}

p {
    color: $emerald;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
}

sup {
    top: -0.4em;
    font-size: 65%;
    line-height: 100%;
}

button, a.button {
    border-radius: 30px;
    background-color: $chartreuse;
    color: $emerald;
    border: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    margin-bottom: 0;
    &:hover {
        background-color: $chartreuse;
        color: $emerald;
    }
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.75);
    z-index: 4;
}

@media screen and (min-width: 1101px) {
    .scrollmagic-pin-spacer {
        display: none!important;
    }
}