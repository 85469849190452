.cookies {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 20px 0;
    background-color: $lightblue;
    z-index: 2;
    h3, p {
        color: $emerald;
    }
    p {
        margin-bottom: 0;
    }
    @media screen and (max-width: 768px) {
        .column.column-75 {
            width: 100%;
            max-width: 100%;
        }
        button {
            margin-top: 15px;
        }
    }
}