form {
    input, select, input[type=text], input[type=select], input[type=email] {
        max-width: 500px;
        width: 100%;
        padding: 12px 8px;
        border: 1px solid #006062;
        background: #F3F3F3;
        border-radius: 0;
        height: 50px;
        margin-bottom: 5px;
    }
    label {
        display: block;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        color: $emerald;
    }
    input.small {
        width: 235px;
    }
    .required_text {
        font-size: 13px;
        margin: 0px 0 15px;
        color: $emerald;
        font-weight: 400;
    }
    .opt-in {
        color: $body;
    }
    .required_asterisk,
    .pristine-error {
        color: $red;
        font-style: italic;
    }
    .has-danger input {
        border: 2px solid $red;
    }
    .pristine-error {
        font-size: 12px;
    }
    select {
        appearance: none;
        background-image: url("/assets/img/content/caret.svg");
        background-size: 20px 14px;
        background-repeat: no-repeat;
        background-position: right center;
        background-position-x: 98%;
        &:focus {
            background-image: url("/assets/img/content/caret.svg");
        }
        &:active {
            background-image: url("/assets/img/content/caret-reverse.svg");
        }
        font-size: 16px;
        font-style: italic;
        color: $grey;
        max-width: 500px;
        border: solid 1px #006062;
        margin-bottom: 30px;
        &.selected {
            font-style: normal;
            color: $grey;
        }
    }
}