.accordion {
    position: relative;
    @media screen and (max-width: 768px) {
        padding-top: 30px;
    }
    .toggle {
        position: absolute;
        top: -50px;
        right: 0;
        background-color: #A50064;
        border-radius: 15px;
        padding: 2px 20px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        cursor: pointer;
        z-index: 2;
        @media screen and (max-width: 768px) {
            top: 0;
        }
    }
    .item {
        position: relative;
        padding-top: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid $emerald;
        &::after {
            content: "";
            width: 7px;
            height: 7px;
            background: $emerald;
            position: absolute;
            border-radius: 8px;
            right: 0;
            bottom: -4px;
        }
        &:last-of-type {
            margin-bottom: 30px;
            @media screen and (max-width: 768px) {
                margin-bottom: 25px;
            }
        }
        h3 {
            color: $lightemerald;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                padding-right: 50px;
            }
        }
        .toggle-item {
            position: absolute;
            right: 0;
            cursor: pointer;
            transform: rotate(180deg);
            &.rotate {
                transform: rotate(360deg);
            }
        }
        h3 {
            padding-right: 40px;
        }
        p {
            margin-bottom: 10px;
            color: $body;
        }
        .answer {
            color: $body;
            line-height: 1.2em;
            padding-right: 40px;
            font-weight: 400;
            display: none;
            ul, li {
                color: $body;
                font-weight: 400;
            }
            img {
                margin: 30px 0;
            }
        }
    }
}