section.main, section.subpage {
    aside {
        width: 300px;
        position: absolute;
        right: 0;
        height: 100%;
        @media screen and (max-width: 1100px) {
            display: none;
            position: fixed;
            width: 240px;
            top: 150px;
            left: 0;
            margin: 0 auto;
            height: auto;
            padding: 15px;
            position: fixed;
            z-index: 5;
            border-radius: 0 0 30px 30px;
            background: $white;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
        nav {
            position: sticky;
            top: 180px;
            @media screen and (min-width: 1100px) {
                padding-bottom: 30px;
            }
            @media screen and (max-width: 1100px) {
                ul {
                    margin-bottom: 0;
                }
            }
            ul, li {
                list-style: none;
            }
            li {
                position: relative;
                padding-left: 50px;
                margin-bottom: 85px;
                line-height: 1.2em;
                max-width: 220px;
                padding-top: 8px;
                min-height: 35px;
                @media screen and (min-width: 1100px) {
                    min-height: 48px;
                }
                a {
                    font-weight: 400;
                    color: $emerald;
                    text-decoration: none;
                    padding-right: 3px;
                    min-height: 40px;
                    position: absolute;
                    width: 220px;
                    padding-left: 50px;
                    left: 0;
                    z-index: 4;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                @media screen and (max-width: 1099px) {
                    max-width: 100%;
                    margin-bottom: 50px;
                    padding-top: 6px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 14px;
                    top: 5px;
                    height: 25px;
                    width: 25px;
                    display: block;
                    border-radius: 50%;
                    background-color: $white;
                    border: 2px solid $white;
                    outline: 2px solid $emerald;
                    z-index: 3;
                    @media screen and (max-width: 1100px) {
                        left: 8px;
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    height: 90px;
                    width: 3px;
                    display: block;
                    background-color: #003840;
                    left: 25px;
                    top: 38px;
                    z-index: 1;
                    @media screen and (max-width: 1100px) {
                        top: 38px;
                        left: 18px;
                        height: 42px;
                    }
                }
                &:last-of-type::after {
                    display: none;
                }
                &.active {
                    color: $emerald;
                    a {
                        font-weight: 700;
                    }
                    &::before {
                        background-color: $fuschia;
                    }
                    span {
                        display: none;
                    }
                }
                &:not(.active) {
                    span {
                        display: inline-block;
                    }
                }
            }
        }
    }
    
    #mobile-nav {
        display: block;
        opacity: 0;
        background-color: $chartreuse;
        color: $emerald;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
        transition: all 0.2s ease-out;
        @media screen and (max-width: 1100px) {
            width: 266px;
            text-transform: uppercase;
            padding-left: 0;
            padding-right: 0;
            z-index: 9;
            top: 175px;
            &.active-scroll {
                display: block;
                left: calc(50% - 133px)!important;
                top: 120px!important;
                opacity: 1;
                width: 266px!important;
                &.hide {
                    display: none;
                }
            }
            &::before, &::after {
                content: "";
                background-color: $emerald;
                -webkit-mask: url('/assets/img/content/caret-white.svg') no-repeat center;
                mask: url('/assets/img/content/caret-white.svg') no-repeat center;
                background-repeat: no-repeat;
                background-size: 100%;
                position: absolute;
                height: 26px;
                width: 22px;
                top: 14px;
                transform: rotate(180deg);
                height: 12px;
            }
            &::before {
                left: 21px;
            }
            &::after {
                right: 21px;
            }

            &.opened {
                background-color: $emerald;
                color: $white;
                &::before, &::after {
                    background-color: $white;
                    background-image: url('/assets/img/content/caret-white.svg');
                }
            }
        }
    }
    &.faq {
        .callout {
            margin-top: 0;
        }
        @media screen and (max-width: 1100px) {
            #mobile-nav {
                top: 300px!important;
            }
            aside {
                top: 330px;
            }
        }

        @media screen and (max-width: 768px) {
            #mobile-nav {
                top: 210px!important;
            }
            aside {
                top: 240px;
            }
        }
    }
}