section.main, section.subpage {
    min-height: 100px;
    position: relative;
    @media screen and (min-width: 1100px) {
        &#main {
            &::before, &::after {
                content: "";
                width: 155px;
                display: block;
                background-image: url(/assets/img/content/lines.svg);
                background-repeat: repeat-y;
                position: absolute;
                left: 0;
                top: -100px;
                height: 120%;
                height: calc(100% + 170px);
                z-index: 0;
            }
            &::before {
                content: unset;
                left: 0;
                right: unset;
                transform: scaleX(-1);
                top: 50px;
            }
            &::after {
                left: unset;
                right: -2em;
            }
        }
    }
    @media screen and (min-width: 1350px) {
        &#main {
            &::before {
                content: "";
                left: -12em;
            }
            &::after {
                right: -8em;
            }
        }
    }
    a {
        color: $fuschia;
        text-decoration: underline;
        font-weight: 700;
        &.button {
            color: $emerald;
            text-decoration: none;
        }
    }
    .row {
        max-width: 800px;
        @media screen and (max-width: 1100px) {
            max-width: 100%;
        }
        &.data {
            .number {
                position: relative;
                font-size: 64px;
                font-weight: 700;
                text-align: center;
                height: 85px;
                
                &.fuschia {
                    padding-left: 35px;
                }

                sup {
                    top: -0.5em;
                    font-size: 60%;
                }

                span {
                    position: absolute;
                    left: -10px;
                    top: 0;
                    font-size: 14px;
                    color: $grey;
                    font-weight: 400;
                    &:first-of-type {
                        right: 92px;
                        top: 20px;
                        font-size: 18px;
                    }
                }
            }
            p {
                text-align: center;
                color: $grey;
            }
        }

        .references, .footnotes {
            p {
                font-size: 11px;
                margin-bottom: 0;
                position: relative;
                word-wrap: break-word;
                sup.gutter {
                    position: absolute;
                    left: 0px;
                    top: 0;
                }
            }
        }

        .references {
            p {
                color: $black;
            }
        }

        .footnotes {
            margin-top: 20px;
            p {
                color: $darkemerald;
                padding-left: 5px;
                margin-top: 4px;
                &.no-sup {
                    padding-left: 0;
                }
                &.footnote {
                    margin-top: 9px;
                }
            }
        }

        ul  {
            list-style: none;
            padding-left: 13px;
            li {
                position: relative;
                color: $emerald;
                line-height: normal;
                margin-bottom: 15px;
                font-weight: 400;
                &::before {
                    position: absolute;
                    left: -10px;
                    top: 0px;
                    content: "\2022";
                    color: $emerald;
                    font-weight: 700;
                }
            }
        }

        &.sitemap {
            ul {
                list-style: none;
                padding-left: 0;
                li {
                    &::before {
                        content: unset;
                    }
                    a {
                        color: $fuschia;
                        font-weight: 700;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}