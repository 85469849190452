table {
    thead {
        background: #005F62;
        background: linear-gradient(90deg, $lightemerald 0%, $emerald 100%);
        tr {
            th {
                color: #fff;
                sup {
                    top: -0.7em;
                }
                &:nth-last-of-type(2) {
                    border-left: 1px solid $white;
                    border-right: 1px solid $white;   
                }
            }
        }
    }
    tfoot {
        tr {
            td {
                color: $emerald;
                text-align: center;
                font-weight: 700;
                border-bottom: none;
                a, span {
                    color: #A71865;
                }
                a {
                    text-decoration: underline;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                color: $grey;
                border-bottom: 1px solid $chartreuse;
                &:nth-last-of-type(2) {
                    border-left: 1px solid $chartreuse;
                    border-right: 1px solid $chartreuse;
                }
            }
        }
    }
    &.two-column {
        tbody {
            tr {
                td {
                    &:nth-last-of-type(2) {
                        border-left: 0;
                    }
                }
            }
        }
    }
    tr {
        td, th {
            line-height: 18px;
            font-weight: 400;
            &:first-of-type {
                padding-left: 15px;
                border-left: none;
            }
            &:last-of-type {
                padding-right: 15px;
                border-right: none;
            }
            padding: 20px 15px;
            ul, li {
                padding-bottom: 0;
                margin-bottom: 0;
                color: $grey!important;
            }

            ul {
                li {
                    margin-bottom: 4px!important;
                    &:last-of-type {
                        margin-bottom: 0!important;
                    }
                }
            }

            @media screen and (max-width: 410px) {
                font-size: 14px;
                padding: 20px 10px;
            }
        }
        &:nth-of-type(even) {
            background-color: $lightgreen;
        }
    }
    &.two-column {
        td, th {
            width: 50%;
        }
    }
}