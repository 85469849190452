.vertical-align {
    align-items: center;
    justify-content: center;
}

.center {
    text-align: center;
}

.strong {
    font-weight: 700;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.desktop {
    display: block;
}

.mobile {
    display: none;
}

// Gradient border buttons
button[type="submit"], a.cta {
    padding: 0px 50px;
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 30px;
    background: linear-gradient(to right,  $chartreuse, $emerald);
    cursor: pointer;
}

button[type="submit"]::before, a.cta::before {
    content: "";
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    border-radius: 30px;
    background-color:  $chartreuse;
    z-index: -1;
    transition: 200ms
}

button[type="submit"]::after, a.cta::after {
    content: attr(data);
    font-size: 16px;
    background: linear-gradient(to left,  $chartreuse, $emerald);
    -webkit-background-clip: text;
    color: transparent;
    transition: 200ms
}

@media screen and (max-width: 1100px) {
    img.desktop {
        text-align: center;
        margin: 0 auto;
    }
    img.mobile {
        text-align: center;
        margin: 0 auto;
    }
}

@media screen and (max-width: 768px) {
    .desktop {
        display: none;
    }
    
    .mobile {
        display: block;
    }
}

.emerald {
    color: $emerald;
}

.lightemerald {
    color: $lightemerald
}

.darkemerald {
    color: #003940;
}

.chartreuse {
    color: #CADB2A;
}

.fuschia {
    color: #A50064;
}

.lightblue {
    color: #DFF8F6;
}

.lightgreen {
    color: #FAFBEA;
}

.white {
    color: #fff;
}

.grey {
 
    color: #626366;
}