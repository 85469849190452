header {
    z-index: 10;
    transition: all 0.2s ease-out;
    transform: translateY(-40px);
    position: fixed;
    width: 100%;
    display: block;
    
    &:not(.scrolled) {
        transform: translateY(0px);
        transition: all 0.5s ease-out;
    }

    .disclaimer {
        background: #000;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 55.21%, rgba(0, 0, 0, 0.00) 100%);
        text-align: center;
        color: $white;
        padding-top: 10px;
        padding-bottom: 10px;
        color: #FFF;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
        transition: background-color 0.5s linear;
        z-index: 2;
    }
    .main-nav {
        transition: all 0.5s ease-out;
        background: rgba(0, 57, 64, 1);
        background: linear-gradient(90deg, rgba(0, 57, 64, 0.75) 0%, rgba(0, 96, 98, 0.75) 49.94%, rgba(0, 56, 64, 0.75) 99.45%);
        padding-top: 10px;
        padding-bottom: 10px;
        transition: background-color 0.5s linear;
        z-index: 2;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        .row {
            @media screen and (max-width: 768px) {
                flex-direction: row;
            }
            .column {
                img {
                    display: inline-block;
                    height: auto;
                    position: relative;
                    top: 4px;
                }
                &:last-of-type {
                    display: flex;
                    justify-content: right;
                }
            }
            nav {
                ul, li {
                    text-decoration: none;
                    display: inline-block;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                        text-align: right;
                    }
                }
                li {
                    a.nav-link {
                        color: #fff;
                        margin-right: 50px;
                        font-weight: 500;
                        @media screen and (max-width: 768px) {
                            margin-right: 15px;
                            text-align: right;
                            display: block;
                            font-size: 13px;
                        }
                    }
                    a.button {
                        @media screen and (max-width: 768px) {
                            font-size: 13px;
                            padding: 11px 14px;
                            line-height: 1.3em;
                            margin-top: 5px;
                        }
                    }
                }
            }   
        }
    }
}