$emerald: #003840;
$lightemerald: #005f61;
$darkemerald: #003940;
$chartreuse: #CADB2A;
$fuschia: #A50064;
$red: #E74C39;
$lightblue: #DFF8F6;
$lightgreen: #FAFBEA;
$white: #fff;
$grey: #626366;
$body: #222;
$black: #000;