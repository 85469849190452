section {
    .callout {
        height: 140px;
        position: relative;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: left;
        z-index: 1;
        @media screen and (max-width: 768px) {
            height: 85px;
        }
        &.first {
            margin-top: 0;
        }
        h2 {
            position: relative;
            max-width: 600px;
            padding: 32px 20px 32px 0;
            margin-bottom: 0;
            position: relative;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
                max-width: 80%;
            }
            @media screen and (max-width: 768px) {
                max-width: 90%;
                padding-top: 0;
                padding-bottom: 0;
                margin-bottom: 0;
                margin-top: 0;
                position: relative;
                line-height: 20px;
            }
        }
        &::before {
            content: "";
            background-image: url("/assets/img/content/callout.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            display: block;
            width: 100%;
            height: 140px;
            position: absolute;
            left: -190px;
            @media screen and (max-width: 768px) {
                height: 85px;
                left: -2rem;
                background-image: url("/assets/img/content/callout-mobile.svg");
            }
        }
        &.large {
            margin-bottom: 30px;
            h2 {
                padding-top: 40px;
                padding-bottom: 40px;
                padding-top: 0;
                padding-bottom: 0;
                margin-bottom: 0;
                margin-top: 0;
            }
            &::before {
                height: 175px;
                @media screen and (max-width: 768px) {
                    background-image: url("/assets/img/content/callout-large.svg");
                }
                @media screen and (min-width: 1100px) {
                    height: 180px;
                    background-image: url("/assets/img/content/callout-large.svg");
                }
                @media screen and (max-width: 768px) {
                    background-image: url("/assets/img/content/callout-large-mobile.svg");
                    height: 105px;
                }
            }
        }
        &.large-mobile {
            &::before {
                @media screen and (max-width: 768px) {
                    background-image: url("/assets/img/content/callout-large-mobile.svg");
                }
            }
        }
    }

    .callout-content {
        height: 140px;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        .callout-button {
            display: flex;
            display: flex;
            justify-content: center;
        }
        &.request-rep {
            .row {
                .column {
                    p {
                        padding-top: 0;
                        padding-bottom: 0;
                        font-weight: 500;
                    }
                }
            }
            @media screen and (max-width: 768px) {
                height: 175px;
                padding-top: 0;
                padding-bottom: 0;
                &::before {
                    background-image: url("/assets/img/content/callout-subpage-mobile-large.svg")!important;
                    height: 185px;
                }
                .row {
                    .column {
                        p {
                            padding-top: 0;
                            padding-bottom: 0;                            
                            text-align: center;
                            padding-right: 30px;
                        }
                        a.button {
                            margin-top: 25px;
                        }
                    }
                }
            }
        }
        &.first {
            margin-top: 0;
        }
        p {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            position: relative;
            max-width: 600px;
            padding: 40px 50px;
            font-size: 20px;
            margin: 0;
            text-align: center;
            max-width: 100%;
            @media screen and (max-width: 768px) {
                padding: 0 10px;
                font-size: 16px;
            }
        }
        a {
            position: relative;
            margin-top: 10px;
            color: $emerald;
            text-decoration: none;
        }
        .row {
            .column {
                p {
                    padding-right: 10px;
                    text-align: left;
                    padding-left: 30px;
                    padding-right: 10px;
                    padding-top: 50px;
                }
            }
        }
        &::before {
            content: "";
            background-image: url("/assets/img/content/callout-content.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            display: block;
            width: 100%;
            height: 155px;
            position: absolute;
            @media screen and (max-width: 768px) {
                background-image: url("/assets/img/content/callout-content-mobile.svg");
                height: 155px;
            }
        }
    }
    &.subpage {
        .callout-content {
            &::before {
                background-image: url("/assets/img/content/callout-subpage.svg");
            }
        }
        &.faq {
            [data-sidebar] {
                .callout {
                    margin-top: 0;
                    &.large {
                        margin-top: 15px;
                        margin-bottom: 20px;
                        @media screen and (max-width: 768px) {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
}