footer {
    background-color: $emerald;
    font-size: 13px;
    line-height: 15px;
    font-weight: 400;
    color: #fff;
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    z-index: 1;
    nav {
        ul, li {
            text-decoration: none;
            display: inline-block;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        li {
            position: relative;
            padding-right: 60px;
            a {
                color: #fff;
                text-decoration: underline;
            }
            @media screen and (min-width: 769px) {
                &::after {
                    content: "|";
                    position: absolute;
                    right: 30px;
                }
            }
            &:last-of-type::after {
                content: unset;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                text-align: center;
                padding-bottom: 20px;
                padding-right: 0;
            }
        }
        padding-bottom: 20px;
    }
    @media screen and (max-width: 768px) {
        .row {
            .column.column-75 {
                flex: 0 0 100%;
                max-width: 100%;   
            }
            .column {
                text-align: center;
                img {
                    margin-bottom: 15px;
                }
            }
            &:last-of-type {
                flex-direction: column-reverse;
            }
        }
    }
}