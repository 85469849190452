section {
    &.hero {
        background-image: url(/assets/img/hero/home.png), linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);
        background-repeat: no-repeat;
        min-height: 600px;
        background-position: bottom;
        background-size: cover;
        padding-top: 160px;
        margin-bottom: 0;
        position: relative;
        z-index: 1;
        @media screen and (min-width: 1101px) {
            margin-bottom: 38px;
        }
        @media screen and (max-width: 768px) {
            background-image: url(/assets/img/hero/home-mobile.png), linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);
            padding-top: 350px;
            padding-bottom: 80px;
            text-align: center;
        }
        h1 {
            width: 450px;
            @media screen and (max-width: 768px) {
                width: 100%;
                font-size: 26px;
            }
            background: linear-gradient(90deg, #F7FFB0 0%, #CADB2A 85.23%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            span {
                text-transform: lowercase;
            }
        }
        small {
            display: block;
        }
        .highlight {
            background-image: url('/assets/img/home/hero-callout.svg');
            background-repeat: no-repeat;
            background-position: center;
            color: #FFF;
            font-size: 18px;
            line-height: 20px;
            font-weight: 600;
            letter-spacing: 0.36px;
            max-width: 360px;
            height: 80px;
            padding: 17px 25px;
            width: 100%;
            @media screen and (max-width: 1100px) {
                margin: 0 auto;
                font-size: 13px;
                line-height: 15px;
                text-align: center;
                background-size: 95%;
                background-position: center;
                display: flex;
                align-items: center;
            }
        }
        .learn-more {
            color: $emerald;
            text-align: center;
            font-size: 16px;
            font-weight: 900;
            text-transform: uppercase;
            border-radius: 32.5px;
            background: $chartreuse;
            padding: 15px 80px;
            position: absolute;
            bottom: -12px;
            left: 45%;
            left: calc(50% - 130px);
            @media screen and (max-width: 768px) {
                left: calc(50% - 120px);
                bottom: -15px;
                padding: 5px 70px;
            }
            &::before, &::after {
                content: "";
                background-image: url('/assets/img/content/caret.svg');
                background-repeat: no-repeat;
                background-size: 100%;
                position: absolute;
                height: 14px;
                width: 26px;
                top: 21px;
                @media screen and (max-width: 768px) {
                    top: 12px;
                }
            }
            &::before {
                left: 21px;
            }
            &::after {
                right: 21px;
            }
            
        }
        &.subpage {
            background-image: url(/assets/img/hero/subpage.png), linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);;
            background-position: bottom;
            margin-bottom: 0;
            @media screen and (min-width: 1101px) {
                margin-bottom: 38px;
            }
            @media screen and (max-width: 768px) {
                background-image: url(/assets/img/hero/subpage-mobile.png), linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);;
                background-size: 100% 100%;
                padding-top: 160px;
                padding-bottom: 20px;
                min-height: unset;
            }
            min-height: 333px;
            h1 {
                text-align: left;
                width: 100%;
                padding-top: 50px;
                background: unset;
                background-clip: unset;
                -webkit-text-fill-color: unset;
                color: $chartreuse;
                font-size: 48px;
                font-weight: 300;
                letter-spacing: unset;
                @media screen and (max-width: 768px) {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 32px;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
            &.request-a-rep, &.sitemap, &.error, &.thank-you {
                margin-bottom: 40px;
                h1 {
                    padding-top: 10px;
                }
            }
        }
    }
}